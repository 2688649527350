* {
  box-sizing: border-box;
  outline: none;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1;
}

ol, ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

blockquote, q {
  quotes: none;
  &:before, &:after {
    content: none;
  }
}

a, button, input[type=submit] {
  cursor: pointer;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

figure {
  margin: 0;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  margin: 0;
  appearance: none;
}

input[type=number] {
  appearance: textfield;
}

textarea {
  vertical-align: top;
}

fieldset {
  border: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, p {
  margin: 0;
}
