.footer {
  padding: 60px 0 30px 0;
  position: relative;
  font-size: $font-size-xs;
  line-height: 18px;
  background-color: $color-background-dark;

  a {
    color: $color-white;
  }

  &-content {
    position: relative;
    z-index: 1;
  }

  &-links {
    font-size: $font-size-s;
    &-col {
      >* {
        display: block;
        margin-top: 10px;
      }
    }

    &-title {
      font-size: $font-size-m;
      font-weight: $font-weight-normal;
      margin-top: 0;
      margin-bottom: 20px;
    }
  }

  &-disclaimer {
    margin-top: 30px;
    padding-top: 20px;
    border-top: solid 1px $color-background;
    a {
      font-weight: $font-weight-normal;
    }
  }
}

@include bp(tablet) {
  .footer {
    &-links {
      &-col {
        margin-bottom: 30px;
      }
      &-logo {
        display: none;
      }
    }
    &-disclaimer {
      margin-top: 0;
    }
  }
}

@include bp(mobile) {
  .footer {
    text-align: center;
    &-links {
      &-title {
        margin-bottom: 5px;
      }

      &-col {
        line-height: 26px;
        >* {
          margin-top: 0;
        }
      }
    }
  }
} 
