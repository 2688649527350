.covidBar {
  background: #2E4369;
  color: $color-white;
  text-align: center;
  padding: 15px 0;
  a {
    color: $color-white;
  }

  .emoji {
    margin-right: 10px;
  }
}

@include bp(tablet) {
  .covidBar {
    font-size: 0.9em;;
  }
}
