.about {
  background: url('../../images/overlay-header.svg') $color-white center -10px no-repeat;
  background-size: 100%;
  padding-bottom: 100px;

  &-mission {
    margin-top: 100px;
    margin-bottom: 100px;
    background-color: #F3FAFA;
    position: relative;
    
    &-content {
      padding: 50px;
      &:after {
        content: "";
        position: absolute;
        left: -5%;
        right: -5%;
        bottom: -60px;
        height: 90px;
        background-color: $color-white;
        transform: rotate(5deg);
      }
    }

    img {
      position: relative;
      z-index: 1;
      max-width: 100%;
      margin-top: -40px;
    }

    p {
      margin-top: 30px;
    }
  }

  &-investors {
    text-align: center;

    &-logos {
      margin-top: 50px;
    }

    p {
      margin-top: 30px;
    }
  }
}

@include bp(small_desktop) {
  .about {
    &-mission {
      &-content {
        &:after {
          display: none;
        }
      }
      img {
        width: 100%;
      }

    }
  }
}

@include bp(tablet) {
  .about {
    &-mission {
      margin-top: 40px;
      margin-bottom: 40px;
      &-content {
        padding: 20px;
        font-size: 0.9em;
      }
    }
    img {
      margin-top: 0;
    }
    &-investors-logos {
      margin-top: 20px;
    }
  }
}
