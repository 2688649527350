.articles {
  background: url("../../images/pattern.jpg") center repeat;
  padding: 60px 0;
  font-size: $font-size-xs;

  &-item {
    border-radius: 4px;
    margin-bottom: 20px;
    background-color: $color-white;
    box-shadow: 0 0 5px 0 rgba($color-background, 0.1);

    img {
      width: 100%;
    }

    &-content {
      padding: 20px
    }
  }
  
  a {
    font-size: $font-size-m;
    font-weight: $font-weight-bold;
    margin-bottom: 20px;
    line-height: 20px;
    display: block;
  }
}
