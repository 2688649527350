.mainArticle {
  background: url("../../images/article.png") 90% top no-repeat;
  background-size: 35% auto;
  margin: 60px 0;
  min-height: 500px;
  a {
    display: inline-block
  }
}

@include bp(small_desktop) {
  .mainArticle {
    min-height: 0;
  }
}

@include bp(tablet) {
  .mainArticle {
    background: none;
  }
}
