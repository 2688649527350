.button {
  &-primary, &-success {
    transition: all 500ms ease;
    cursor: pointer;
  }

  &-primary {
    &:after { content: none; }

    display: inline-block;
    text-decoration: none;
    border: solid 1px $color-border-primary;
    color: $color-link;
    text-transform: uppercase;
    font-weight: $font-weight-normal;
    &:hover, &.active {
      background-color: $color-link;
      color: $color-white;
      font-weight: $font-weight-bold;
    }
    &.active {
      pointer-events: none;
    }
  }

  &-success {
    &:after { content: none; }

    border: none;
    display: inline-block;
    text-decoration: none;
    background-color: $color-link-success;
    color: $color-white;
    text-transform: uppercase;
    font-weight: $font-weight-bold;
    &:hover, &.active {
      background-color: $color-link-success-dark;
      color: $color-white;
    }
    &.active {
      pointer-events: none;
    }
  }

  &-form {
    &:after { content: none; }

    border: none;
    display: inline-block;
    text-decoration: none;
    background-color: $color-laurel;
    color: $color-white;
    text-transform: uppercase;
    font-weight: $font-weight-light;
    &:hover, &.active {
      background-color: $color-laurel-dark;
      color: $color-white;
    }
    &:disabled {
      background-color: $color-laurel-light;
    }
    &.active {
      pointer-events: none;
    }
  }

  &-small {
    border-radius: $radius-m;
    padding: 6px 12px;
    text-align: center;
    font-size: $font-size-xs;
    line-height: 22px;

    // button with icon
    &:before {
      display: inline-block;
      margin-right: 5px;
      margin-bottom: -1px;
      height: 15px;
      width: 15px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  &-medium {
    border-radius: $radius-xl;
    padding: 16px 50px;
    text-align: center;
    font-size: $font-size-s;
    line-height: 22px;
    border-width: 2px;
  }

  &-big {
    border-radius: $radius-xl;
    min-width: 360px;
    padding: 18px;
    text-align: center;
    font-size: $font-size-s;
    line-height: 22px;
    border-width: 2px;

    &-icon {
      padding-top: 12px;
      padding-bottom: 12px;
      border-radius: $radius-xxl;
      &:before {
        display: inline-block;
        vertical-align: middle;
        margin-right: 15px;
        height: 43px;
        width: 43px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }
}

@include bp(tablet) {
  .button-shrink-tablet {
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }
}

@include bp(mobile) {
  .button {
    &-big {
      font-size: $font-size-xs;
      line-height: 20px;
      padding: 14px;
    }

    &-big, &-small {
      min-width: 0;
      width: 100%;
      padding-left: 0;
      padding-right: 0;
    }
  }
}
