.title {
  &-main {
    font-size: $font-size-xxl;
    line-height: 76px;
    font-weight: $font-weight-bold;
    color: $color-text-positive-darker;
  }

  &-primary {
    font-size: $font-size-xl;
    line-height: 34px;
    font-weight: $font-weight-bold;
    color: $color-text-positive-darker;
  }

  &-secondary {
    font-size: $font-size-xl;
    line-height: 34px;
    font-weight: $font-weight-normal;
    color: $color-link;
    text-align: center;
  }

  &-tertiary {
    font-size: $font-size-xl;
    line-height: 34px;
    font-weight: $font-weight-semibold;
    color: $color-text-positive-dark;
    text-align: center;
  }
}

.subtitle {
  &-main {
    font-size: $font-size-l;
    line-height: 30px;
    
    &.highlight, .highlight {
      font-size: 1.2em;
      color: $color-text-positive-dark;
    }
  }

  &-primary {
    font-size: $font-size-m;
    line-height: 26px;

    p {
      margin-top: 30px;
    }
  }

  &-secondary {
    font-size: $font-size-s;
    line-height: 22px;

    p {
      margin-top: 30px;
    }
  }
}

@include bp(mobile) {
  .title {
    &-main {
      font-size: $font-size-xl;
      line-height: 40px;
      text-align: center;
    }

    &-primary {
      font-size: $font-size-l;
      line-height: 30px;
      text-align: center;
    }

    &-secondary {
      font-size: $font-size-l;
    }
  }

  .subtitle {
    &-main {
      font-size: $font-size-xs;
      line-height: 30px;
      text-align: center;
    }

    &-primary, &-secondary {
      font-size: $font-size-xs;
      line-height: 22px;
      text-align: center;

      p {
        margin-top: 24px;
      }
    }
  }
}
