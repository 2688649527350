$sizesArray: "Tiny", "Small", "Medium", "Big", "Huge", "Enormous";
$colSizes: 12, 10, 9, 8, 7, 6, 5, 4, 3, 2;

.row {
  display: flex;
  flex-wrap: wrap;

  &-separation {
    @each $sizes in $sizesArray {
      $i: index($sizesArray, $sizes);
      &#{$sizes} {
        margin-top: $i*$spacing;
      }
    }
  }

  &-align {
    &Center {
      justify-content: center;
    }

    &Right {
      justify-content: flex-end;
    }

    &Around {
      justify-content: space-around;
    }

    &Between {
      justify-content: space-between;
    }
  }

  .col {

    &-desktop {
      @each $col in $colSizes {
        &#{$col} {
          flex: 0 0 calc(#{$col}/12*100%);
        }
      }
    }

    @include bp(small_desktop) {
      &-smallDesktop {
        @each $col in $colSizes {
          &#{$col} {
            flex: 0 0 calc(#{$col}/12*100%);
          }
        }
      }
    }
    @include bp(tablet) {
      &-tablet {
        @each $col in $colSizes {
          &#{$col} {
            flex: 0 0 calc(#{$col}/12*100%);
          }
        }
      }
    }
    @include bp(mobile) {
      &-mobile {
        @each $col in $colSizes {
          &#{$col} {
            flex: 0 0 calc(#{$col}/12*100%);
          }
        }
      }
    }
  }

  &-gutter {
    @each $sizes in $sizesArray {
      $i: index($sizesArray, $sizes);
      &#{$sizes} {
        margin-right: -$i*($spacing/2);
        margin-left: -$i*($spacing/2);

        .col {
          margin-left: $i*($spacing/2);
          margin-right: $i*($spacing/2);

          &-desktop {
            @each $col in $colSizes {
              &#{$col} {
                flex: 0 0 calc(#{$col}/12*100% - #{$i*$spacing});
              }
            }
          }

          @include bp(small_desktop) {
            &-smallDesktop {
              @each $col in $colSizes {
                &#{$col} {
                  flex: 0 0 calc(#{$col}/12*100% - #{$i*$spacing});
                }
              }
            }
          }
          @include bp(tablet) {
            &-tablet {
              @each $col in $colSizes {
                &#{$col} {
                  flex: 0 0 calc(#{$col}/12*100% - #{$i*$spacing});
                }
              }
            }
          }
          @include bp(mobile) {
            &-mobile {
              @each $col in $colSizes {
                &#{$col} {
                  flex: 0 0 calc(#{$col}/12*100% - #{$i*$spacing});
                }
              }
            }
          }
        }
      }
    }
  }
}

.col {
  flex: 1;
}

@include bp(mobile) {
  .col {
    &-separation {
      @each $sizes in $sizesArray {
        $i: index($sizesArray, $sizes);
        &#{$sizes} {
          margin-top: $i*$spacing;
        }
      }
    }
    flex-basis: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .row {
    margin-left: 0;
    margin-right: 0;
  }
}
