.error, .pristine, .errorForm {
  .multiStepForm-next {
    pointer-events: none;
    background-color: $color-text-negative;
  }
}

.hint {
  display: none;
}

.errorForm .hint {
  font-size: $font-size-s;
  color: $color-text-negative;
  font-weight: $font-weight-bold;
  display: block;
}

.flash {
  display: none;
}

.warning .flash {
  background-color: #FFDD99;
  width: 100%;
  position: relative;
  max-width: 700px;
  margin: 50px auto 0;
  padding: 20px 16px;
  font-size: $font-size-s;
  color: $color-text-positive;
  font-weight: $font-weight-normal;
  border-radius: 5px;
  display: block;
}

.warning .multiStepForm-container{
  margin: 50px auto 0;
}


.multiStepForm {
  background-color: #FAF9F8;
  height: 100%;
  min-height: 100vh;
  
  &-container {
    width: 100%;
    position: relative;
    max-width: 550px;
    margin: 140px auto 0;
    padding-left: 16px;
    padding-right: 16px;
  }

  &-next {
    display: block;
    margin: 40px auto 0;
    width: 47px;
    height: 47px;
    border-radius: 50%;
    background: url("../../images/arrow.svg") center no-repeat $color-link-success;
    border: none;
    transition: all 500ms ease;
    cursor: pointer;

    &:hover {
      background-color: $color-link-success-dark;
    }
  }

  &-prev {
    display: block;
    width: 47px;
    height: 47px;
    background: url("../../images/arrow-back.svg") center no-repeat transparent;
    border: none;
    cursor: pointer;
    position: absolute;
    top: calc(50% - 24px);
    left: -120px;
  }

  &-temp {
    font-size: 13px;
    display: flex;
    margin-top: 100px;
    >* {
      width: 50%;
    }
  }
}

@include bp(tablet) {
  .multiStepForm {
    &-prev {
      left: 3px;
      top: auto;
      bottom: 0;
    }
  }
}
