.bullets {
  .container {
    box-shadow: 16px 12px 22px 5px rgba($color-black, 0.08), 0 0 22px 5px rgba($color-black, 0.04);
    border-radius: 30px 0 0 30px;
    background-color: $color-white;
    padding: 40px 0;
    font-size: $font-size-xs;
    position: relative;
    z-index: 1;

    &:after {
      content: "";
      position: absolute;
      background-color: $color-white;
      box-shadow: 16px 12px 22px 5px rgba($color-black, 0.08);
      z-index: -1;
      right: -50vw;
      top: 0;
      bottom: 0;
      width: 50vw;
    }
  }

  &-title {
    font-size: $font-size-l;
    font-weight: $font-weight-light;
  }

  &-item {
    position: relative;
    padding-top: 80px;
    text-align: center;
    border-right: dotted 1px $color-text-negative-light;
    padding: 80px 40px 0 40px;
    &:last-child {
      border-right: 0;
    }
  }

  &-wallet, &-sofa, &-handshake {
    &:before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }

  &-wallet {
    &:before {
      top: 0;
      background: url("../../images/save.svg") center no-repeat;
      height: 70px;
      width: 54px;
    }
  }

  &-sofa {
    &:before {
      top: 5px;
      background: url("../../images/stress.svg") center no-repeat;
      height: 58px;
      width: 88px;
    }
  }

  &-handshake {
    &:before {
      top: 0;
      background: url("../../images/side.svg") center no-repeat;
      height: 69px;
      width: 83px;
    }
  }
}

@include bp(tablet) {
  .bullets {
    .container {
      border-radius: 0;
    }
  }
}

@include bp(mobile) {
  .bullets {
    &-item:not(:last-child) {
      margin-bottom: 40px;
    }
  }
}
