.error {
  .form-input {
    border: solid 1px $color-error;
    input {
      color: $color-error;
    }
  }

  .form-field-helper {
    color: $color-error;
  }
}
.form {
  &-field {
    margin-bottom: 10px;
    &-helper:not(:empty) {
      margin-top: 10px;
      font-size: $font-size-s;
    }
  }
  &-input {
    border: solid 1px $color-text-negative-light;
    border-radius: $radius-xs;
    display: flex;
    height: 60px;
    overflow: hidden;

    > * {
      height: 100%;
    }
    
    &-currency:before {
      content: "";
      display: inline-block;
      width: 35px;
      background: url("../../images/pound.svg") center right no-repeat $color-white;
    }
    &-percentage:after {
      content: "";
      display: inline-block;
      width: 55px;
      background: url("../../images/percentage.svg") center left no-repeat $color-white;
    }

    input {
      border: 0;
      padding: 0 16px;
      width: 100%;
      font-size: $font-size-l;
      font-weight: $font-weight-bold;
      color: $color-text-positive-darker;

      &::placeholder {
        color: $color-text-positive;
      }
    }
  }
}
