.breadcrumbs {
  background: $color-white;
  position: relative;
  
  &-content {
    line-height: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;
    img {
      height: 25px;
    }
  }
  
  &-close {
    background: url("../../images/close.svg") center center no-repeat $color-background-info-light;
    border-radius: 50%;
    height: 30px;
    width: 30px;
  }
  
  &-status {
    position: absolute;
    bottom: -6px;
    height: 6px;
    width: 100%;
    border-bottom: solid 6px $color-text-negative-light;
  }
  
  &-progress {
    height: 6px;
    background-color: $color-link-light;
  }
}

@include bp(mobile) {
  .breadcrumbs {
    &-content {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }
}
