.legal {
  background: url('../../images/overlay-header.svg') $color-white center -10px no-repeat;
  background-size: 100%;
  font-size: $font-size-s;
  line-height: 22px;
  color: $color-text-positive-dark;
  a {
    text-decoration: none;
  }

  &-title {
    font-size: $font-size-xxl;
    color: $color-text-positive-darker;
    margin-bottom: 45px;
  }

  &-index {
    counter-reset: index;
    font-size: $font-size-s;
    line-height: 22px;
    margin-bottom: 60px;

    li a {
      &:before {
        counter-increment: index;
        content: counter(index) ". ";
      }
    }
  }

  &-list {
    counter-reset: list;

    > li {
      margin-bottom: 40px;
    }

    p {
      margin-bottom: 16px;
    }

    &-title {
      margin-bottom: 8px;
      &:before {
        counter-increment: list;
        content: counter(list) ". ";
      }
    }

    &-ul {
      list-style: initial;
      margin-left: 40px;
      margin-bottom: 20px;
    }
  }
}
