//media queries definitions
$big_desktop-viewport: 1200 !default;
$desktop-viewport: 990 !default;
$tablet-viewport: 768 !default;

$breakpoints: (
  desktop-only: (
    min-width: $desktop-viewport,
  ),
  tablet-only: (
    min-width: $tablet-viewport,
    max-width: $desktop-viewport - 1,
  ),
  small_desktop: (
    max-width: $big_desktop-viewport - 1,
  ),
  tablet: (
    max-width: $desktop-viewport - 1
  ),
  mobile: (
    max-width: $tablet-viewport - 1
  ),
);

@function media-bp($queries) {
  $media-string: ();
  
  @each $key, $value in $queries {
    @if $media-string == (()) {
      $media-string: append($media-string, unquote("(#{$key}: #{$value}px)"));
    }

    @else {
      $media-string: append($media-string, unquote("and (#{$key}: #{$value}px)"));
    }
  }

  @return $media-string;
}

@mixin bp($breakpoint) {

  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-map: map-get($breakpoints, $breakpoint);
    $media: media-bp($breakpoint-map);
    
    @media #{$media} {
      @content;
    }
  }
}
