.rc-collapse {
  &-anim-active {
    transition: height 0.2s ease-out;
  }

  &-content {
    overflow: hidden;
    margin-bottom: 20px;

    &-inactive {
      display: none;
    }
  }

  &-header {
    position: relative;
    cursor: pointer;
    color: $color-text-positive-dark;
    &:after {
      content: "";
      position: absolute;
      top: -2px;
      right: 0;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: url("../../../images/blueCross.svg") no-repeat $color-white 5px 6px;
      background-color: $color-white;
      background-size: 20px;
      box-shadow: 0 1px 4px rgba($color-background, 0.2);
      pointer-events: none;
    }
  }

  &-item-active {
    .rc-collapse-header {
      &:after {
        background: url("../../../images/blueMinus.svg") no-repeat $color-white 5px 10px;
      }
    }
  }
}
