.listGeneric {
  .rc-collapse {
    &-item {
      margin-top: -1px;
      border-bottom: solid 1px $color-border-tertiary-lighter;
      border-top: solid 1px $color-border-tertiary-lighter;
    }

    &-content {
      font-size: $font-size-s;
      padding: 0 50px 0 0;
      p {
        margin-bottom: 10px;
      }
    }

    &-header {
      padding-right: 50px;
      font-size: $font-size-s;
      margin: 20px 0;
    }
  }
}

@include bp(mobile) {
  .listGeneric {
    .rc-collapse-content {
      font-size: 0.9em;
    }
  }
}
