.error404 {
  margin-bottom: 100px;
  &-title {
    color: $color-text-positive-dark;
    font-size: $font-size-xl;
    font-weight: $font-weight-normal;
    line-height: 38px;
  }
  
  &-subtitle {
    color: $color-text-positive-dark;
    font-size: $font-size-l;
    font-weight: $font-weight-bold;
    margin: 20px 0 30px 0;
  }

  &-links {
    margin-top: 60px;
    font-size: $font-size-l;
    font-weight: $font-weight-light;
    >li {
      margin-bottom: 15px;
    }
  }

  &-content {
    display: flex;
    align-items: center;
  }

  &-image {
    text-align: center;
    img {
      max-width: 100%;
    }
  }
}

@include bp(mobile) {
  .error404 {
    text-align: center;
    margin-bottom: 60px;

    &-title {
      font-size: $font-size-l;
      line-height: 38px;
    }
    
    &-subtitle {
      font-size: $font-size-m;
      margin: 10px 0 15px 0;
    }

    &-links {
      margin: 20px 0 50px;
      font-size: $font-size-m;
    }
  }
}
