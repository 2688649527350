.tabs {
  &-list {
    display: flex;
    margin-bottom: 50px;
    font-size: $font-size-s;
    font-weight: $font-weight-bold;
    color: $color-link;

    > li {
      padding: 5px 0;
      cursor: pointer;
      &.active {
        padding: 5px 30px;
        background-color: $color-link;
        color: $color-white;
        border-radius: 15px;
        cursor: default;
      }
      &:not(:first-child) {
        margin-left: 55px;
      }
    }
  }

  &-content {
    > li:not(.active) {
      display: none;
    }
  }
}

@include bp(mobile) {
  .tabs {
    &-list {
      display: block;
      text-align: center;
      > li {
        display: block;
        margin-bottom: 10px;
        &, &.active {
          border-radius: 25px;
          padding: 10px;
        }
        &:not(:first-child) {
          margin-left: 0;
        }
      }
    }
  }
  
}
