.remortgageOptions {
  padding-top: 20px;

  &-title {
    font-size: $font-size-l;
    line-height: 34px;
    font-weight: $font-weight-bold;
    color: $color-text-positive-darker;
    margin-bottom: 20px;
  }
  
  &-bullets {
    margin-top: 40px;
    margin-bottom: 100px;
  }
  
  &-assumptions {
    border-top: solid 1px $color-border-tertiary-lighter;
    margin-top: 10px;
    padding-top: 20px;
    
    &-title {
      font-size: $font-size-s;
      min-height: 32px;
      .highlight {
        margin-left: 20px;
        font-size: $font-size-l;
        font-weight: $font-weight-bold;
        color: $color-text-positive-dark;
      }
    }
  }

  &-card {
    &-title {
      font-size: 80px;
      line-height: 80px;
      font-weight: $font-weight-bold;
      color: $color-text-positive-dark;
      margin-bottom: 10px;
    }
    
    &-subtitle {
      font-size: $font-size-l;
      font-weight: $font-weight-bold;
      margin-top: 5px;
    }

    &-list {
      display: flex;
      text-align: center;
      border-top: solid 1px $color-border-tertiary-lighter;
      font-size: $font-size-s;
      > * {
        width: 33.33%;
        padding: 14px 0 20px;
        &:not(:first-child) {
          border-left: solid 1px $color-border-tertiary-lighter;
        }
      }
    }
  }

  &-noProducts {
    text-align: center;
    max-width: 610px;
    margin: 0 auto;

    .highlight {
      font-size: $font-size-l;
      font-weight: $font-weight-bold;
    }

    img {
      height: 40px;
      margin-bottom: 20px;
    }
  }

  &-empty {
    display: flex;
    text-align: center;
    justify-content: center;
    padding: 30px;
    border: dashed 2px $color-border-tertiary-light;
    border-radius: 16px;
    height: calc(100% - 55px);
    align-items: center;
  }
}

@include bp(small_desktop) {
  .remortgageOptions {
    &-card {
      &-list {
        font-size: $font-size-xs;
        line-height: 18px;
      }
      &-subtitle {
        font-size: $font-size-l;
      }
    }
  }
}

@include bp(tablet) {
  .remortgageOptions {
    .card {
      font-size: 0.9em;
    }
    &-card {
      line-height: 14px;
      &-title {
        font-size: 70px;
        line-height: 70px;
        margin-bottom: 10px;
      }
    }
    &-bullets {
      font-size: 0.9em;
    }
  }
}

@include bp(mobile) {
  .remortgageOptions {
    &-card {
      &-list {
        font-size: $font-size-xxs;
        flex-wrap: wrap;

        > * {
          width: 100%;
          padding-top: 10px;
          &:not(:last-child) {
            padding-bottom: 0;
            width: 50%;
          }
          &:not(:first-child) {
            border-left: 0;
          }
        }
      }
    }
  }
}
