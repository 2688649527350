.steps {
  &-title {
    font-size: 28px;
    line-height: 32px;
    letter-spacing: -0.5;
    color: $color-text-positive-darker;
  }

  &-subtitle {
    font-size: $font-size-s;
    line-height: 21px;
    color: $color-text-negative;
    font-weight: $font-weight-bold;
    margin-top: 26px;
  }

  &-form {
    margin-top: 20px;
  }
}
