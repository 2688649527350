.post {
  > img {
    width: 100%;
    margin-top: -200px;
  }
  &-wrapper {
    margin-top: 50px;
    margin-bottom: 100px;
  }

  &-content {
    margin-top: 60px;

    p, ol, ul {
      margin-bottom: 20px;
    }
  }

  &-alert {
    display: none;
    border-radius: 5px;
    background-color: #FFF3B3;
    margin-bottom: 50px;
    padding: 18px 25px;
    color: $color-text-positive-darker;
    .rc-collapse-header {
      color: $color-text-positive-darker;
      font-weight: $font-weight-bold;
      font-size: $font-size-l;
      &:after {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        margin-left: 20px;
        box-shadow: none;
        background: url("../../images/arrow-down.svg") no-repeat transparent 5px 10px;
        background-size: 14px;
      }
    }
    .rc-collapse-item-active .rc-collapse-header:after {
      background: url("../../images/arrow-up.svg") no-repeat transparent 5px 10px;
      background-size: 14px;

    }
    .rc-collapse-content {
      margin-bottom: 0;
    }

    &-title {
      margin: 20px 0;
      font-weight: $font-weight-bold;
    }

    p {
      margin-bottom: 20px;
    }
  }

  &-title {
    font-weight: $font-weight-bold;
    font-size: $font-size-l;
    margin-top: 50px;
    color: $color-text-positive-darker;
  }
  &-subtitle {
    font-weight: $font-weight-bold;
    font-size: $font-size-m;
    color: $color-text-positive-dark;
  }

  &-table {
    width: 100%;
    text-align: center;
    table-layout: fixed;
    margin-bottom: 40px;

   &.left {
      text-align: left;
    }

    &.noFixed {
      table-layout: auto;
      th {
        text-align: left;
      }
    }

    th {
      background-color: $color-background-info-light;
    }
    
    td, th {
      padding: 10px 20px;
      border: 1px solid $color-text-negative;
    }
  }
}

@include bp(tablet) {
  .post {
    > img {
      margin-top: -100px;
    }
  }
}

@include bp(mobile) {
  .post {
    > img {
      margin-top: 0;
    }
    &-alert {
      margin-bottom: 30px;
      .rc-collapse-header {
        font-size: $font-size-s;
      }
      .rc-collapse-content {
        font-size: 0.8em;
      }
    }
  }
}
