body {
  font-family: $font-family;
  font-size: $font-size-m;
  line-height: 26px;
  color: $color-text-positive;
  text-size-adjust: 100%;
  background-color: $color-background-info-dark;
}

input {
  appearance: none;
  border-radius: 0;
}

.layout {
  width: 1900px;
  overflow: hidden;
  max-width: 100%;
  margin: 0 auto;
  box-shadow: 0 0 10px 2px rgba($color-background, 0.1);

  &-content {
    padding-top: 60px;
  }
}

.container {
  width: 1200px;
  max-width: 100%;
  margin: 0 auto;
  padding-left: 30px;
  padding-right: 30px;
}

a, .fakeLink {
  position: relative;
  text-decoration: none;
  color: $color-link;
  transition: color ease 200ms;
  &:after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 50%;
    right: 50%;
    width: 0;
    height: 2px;
    transform: translate(-50%, -50%);
    background-color: $color-link;
    transition: width ease 300ms;
  }
  &:hover {
    color: $color-link-dark;
    &:after {
      width: 100%;
    }
  }

  &.link-white {
    &:after {
      background-color: $color-white;
    }
  }

  &.no-underline:after {
    content: none;
  }
}

.fakeLink {
  border: 0;
  background-color: transparent;
}

@include bp(mobile) {
  .container {
    padding-left: 16px;
    padding-right: 16px;
  }

  .layout {
    &-content {
      padding-top: 30px;
    }
  }

  a, .fakeLink {
    &:after {
      content: none;
    }
  }
}
