.card {
  background-color: $color-white;
  border-radius: 16px;
  box-shadow: 0 0 5px 0 rgba($color-background, 0.1);

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    
    img {
      height: 40px;
      margin-right: -10px;
    }
  }

  &-wrapper {
    padding: 10px 20px;
  }

  &-title {
    font-size: $font-size-m;
    line-height: 20px;
    font-weight: $font-weight-semibold;
  }

  .rc-collapse-header:after {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-left: 20px;
  }
}

@include bp(mobile) {
  .card {
    &-wrapper {
      padding: 10px 20px;
    }
    &-title {
      font-size: $font-size-s;
    }
    &-cta {
      margin-bottom: 30px;
      button {
        width: 100%;
      }
    }
  }
}
