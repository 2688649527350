.popup {
  width: 20px;
  height: 20px;
  &:hover {
    .popup-content {
      display: block;
    }
  }
  &-wrapper {
    position: relative;
    display: inline-block;

    > * {
      display: inline-block;
    }
  }
  &-content {
    position: absolute;
    right: -10px;
    bottom: 30px;
    font-size: $font-size-xs;
    padding: 10px;
    border-radius: 4px;
    line-height: 16px;
    z-index: 1;
    display: none;
    width: 300px;
    background: $color-white;
    border: solid 1px $color-text-negative-light;
    font-weight: $font-weight-semibold;
    box-shadow: 0 4px 0 rgba($color-background, 0.2);
  }

  &:before {
    content: "";
    position: absolute;
    top: 4px;
    right: -2px;
    width: 20px;
    height: 20px;
    background: url('../../images/info.svg') center no-repeat;
    background-size: contain;
  }

  &.popup-right {
    .popup-content {
      left: -20px;
    }
  }
}

@include bp(mobile) {
  .popup {
    &.popup-right {
      .popup-content {
        left: -10px;
      }
    }
    &.popup-center-mobile {
      .popup-content {
        left: -100px;
      }
    }
  }
}
