.claim {
  background: url("../../images/homeheader.png") right top no-repeat;
  background-size: 50% auto;
  padding-bottom: 60px;
  &-content {
    z-index: 1;
  }

  &-financial {
    img {
      height: 90px;
    }
  }
}

@include bp(tablet) {
  .claim {
    background: url("../../images/overlay1.svg") left top no-repeat;
  }
}

@include bp(mobile) {
  .claim {
    background-size: 65%;
    &-financial {
      text-align: center;
    }
  }
}
