.lenders {
  background: $color-background-info-light;
  margin-top: -50px;
  padding-top: 100px;
  padding-bottom: 80px;
  &-content {
    margin-top: 40px;
    text-align: center;
    >* {
      padding: 20px 40px;
    }
  }
}
