.productsDetail {
  &-list {
    margin-bottom: 40px;
    padding: 0 40px;
    position: relative;
    display: flex;
    padding-top: 90px;
    font-size: $font-size-s;
    color: $color-text-positive-dark;
    > ul {
      width: 50%;
      padding: 0 20px;

      > li {
        margin-bottom: 4px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: solid 1px $color-text-negative-light;
        padding: 10px 0;
        min-height: 64px;
      }

    }

    &-title {
      color: $color-text-positive-darker;
      font-weight: $font-weight-bold;
      font-size: $font-size-l;
    }

    &-subtitle {
      color: $color-text-positive-light;
      line-height: 16px;
      font-size: $font-size-xs;
    }

    &-logo {
      left: 50%;
      transform: translateX(-50%);
      position: absolute;
      top: -30px;
      height: 60px;
    }

    &-cta {
      text-align: center;
    }

    &-disclaimer {
      margin-top: 50px;
      font-size: $font-size-xxs;
      line-height: 18px;
      padding: 0 20px;
      color: $color-text-positive;

      &-title {
        font-size: $font-size-s;
      }

      ul {
        margin: 20px 0;
        list-style-type: disc;
        margin-left: 40px;
      }

      p {
        margin-bottom: 20px;
      }

      &-quote {
        font-style: italic;
        font-size: $font-size-s;
        margin-bottom: 20px;
      }

      .highlight {
        font-weight: $font-weight-bold;
        font-size: $font-size-s;
        margin-bottom: 0;
      }
    }
  }

  &-habito {
    font-size: $font-size-l;
    text-align: center;
    padding: 50px 100px;
    background-color: $color-background-info-light;
    color: $color-text-positive-darker;
    &:before {
      content: "";
      display: block;
      height: 40px;
      background: url("../../images/habito-logo.svg") center no-repeat;
      background-size: contain;
      margin-bottom: 20px;
    }
  }
}

@include bp(mobile) {
  .productsDetail {
    &-list {
      font-size: 0.8em;
      padding-left: 20px;
      padding-right: 20px;
      display: block;
      
      > ul {
        width: 100%;

        > li {
          min-height: auto;
        }
      }
    }

    &-habito {
      padding: 40px 50px;
      font-size: 0.9em;
    }
  }
}
