// Colors
// -- Generic
$color-black: black;
$color-white: white;
// -- Primary
$color-marengo: #536080;
$color-santas: #9C9CB0;
$color-slate: #6B7C93;
$color-martinique: #32325D;
$color-nile: #172F51;
$color-bay: #23527C;
$color-mirage: #1D1D39;
$color-smoke: #898989;
// -- Secondary
$color-electric: #00D1FF;
$color-sky: #299EDF;
$color-bondi:#0687B6;
$color-viking: #6AB4D5;
$color-laurel: #00B300;
$color-laurel-dark: #009300;
$color-laurel-light: #92dd9b;
$color-bird: #F0AC2B;
$color-bird-light: #F9DDA8;
$color-bird-dark: #DFA029;
$color-soft: #F2F2F7;
$color-sepia: #fcf7f7;
$color-error: #E02020;

// -- Texts
$color-text-positive: $color-slate;
$color-text-positive-dark: $color-marengo;
$color-text-positive-darker: $color-mirage;
$color-text-positive-light: $color-smoke;
$color-text-positive-lighter: $color-bay;

$color-text-negative: $color-santas;
$color-text-negative-light: lighten($color-santas, 25%);

// -- Backgrounds
$color-background: $color-martinique;
$color-background-dark: $color-mirage;
$color-background-info: $color-viking;
$color-background-info-light: $color-soft;
$color-background-info-dark: $color-sepia;

$color-link: $color-sky;
$color-link-light: $color-electric;
$color-link-dark: $color-bondi;
$color-link-success: $color-laurel;
$color-link-success-dark: $color-laurel-dark;
$color-button-form: $color-bird;
$color-button-form-disabled: $color-bird-light;
// -- Borders
$color-border-primary: $color-sky;
$color-border-secondary: $color-martinique;
$color-border-secondary-light: $color-nile;
$color-border-tertiary: $color-slate;
$color-border-tertiary-light: lighten($color-border-tertiary, 30%);
$color-border-tertiary-lighter: lighten($color-border-tertiary, 40%);
$color-border-tertiary-lightest: lighten($color-border-tertiary, 45%);

// Spacing
$spacing: 10px;

// Radius
$radius-xs: 4px;
$radius-s: 8px;
$radius-m: 18px;
$radius-l: 24px;
$radius-xl: 30px;
$radius-xxl: 70px;

// Fonts
$font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
// -- Sizes
$font-size-xxl: 60px;
$font-size-xl: 32px;
$font-size-l: 20px;
$font-size-m: 18px;
$font-size-s: 16px;
$font-size-xs: 14px;
$font-size-xxs: 12px;
// -- Weights
$font-weight-bold: 700;
$font-weight-semibold: 600;
$font-weight-normal: 400;
$font-weight-light: 300;
