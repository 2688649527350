.navbar {
  background: rgba($color-white, 0.96);
  position: relative;
  
  &-content {
    line-height: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 25px;
    padding-bottom: 25px;
  }
}

@include bp(mobile) {
  .navbar {
    &-content {
      display: block;
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }
}
